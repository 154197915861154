<template>
  <AView
    :value="value"
    :name="service.name"
    :active="service.id === activeServiceId"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onSave="onSave"
    @onClear="onClear"
    @onNavigate="onNavigate"
  >
    <template v-slot:content="{}">
      <ServiceSectionFormOverviewVue
        ref="form"
        class="pt-4"
        v-model="service"
      ></ServiceSectionFormOverviewVue>
    </template>
  </AView>
</template>
  
  <script>
import { mapGetters, mapState } from "vuex";
import AView from "../../../../../../../components/wad/organisms/layout/A-View.vue";
import ServiceSectionFormOverviewVue from "../../../../../../../components/wad/organisms/services/editorForms/ServiceSectionFormOverview.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    ServiceSectionFormOverviewVue,
  },
  data() {
    return {
      service: {},
    };
  },
  created() {
    this.$store.dispatch("ServiceStore/GetService", {
      id: this.value.relation.params.serviceId,
    });
    this.service = this.serviceById(this.value.relation.params.serviceId);
  },

  computed: {
    ...mapState("ServiceStore", ["activeServiceId", "displayedServices"]),
    ...mapGetters("ServiceStore", ["serviceById"]),
  },

  methods: {
    onNavigate() {
      this.$store.dispatch("ServiceStore/setActiveSection", {
        serviceId: this.value.relation.params.serviceId,
        section: "overview",
      });
    },
    onHover() {
      console.log("OnHover");
      this.$store.commit(
        "ServiceStore/setActiveId",
        this.value.relation.params.serviceId
      );
    },
    onFocus() {},
    onSave() {},
    onClear() {},
  },
  watch: {
    displayedServices: {
      async handler(newVal) {
        if (newVal && !newVal.IN_PROGRESS) {
          this.service = this.serviceById(this.value.relation.params.serviceId);
        }
      },
      deep: true,
    },
    service: {
      async handler(newVal, oldVal) {
        if (!(oldVal && oldVal.id)) return;

        if (this.$refs.form && this.$refs.form.validate())
          await this.$store.dispatch("ServiceStore/UpdateService", {
            service: newVal,
          });
      },
      deep: true,
    },
  },
};
</script>