<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col class="overview-editor-col" cols="6" lg="8" md="8" sm="6">
        <HelpFormInputVue
          dark
          :rows="6"
          v-model="form.overview"
          :rules="serviceOverviewRules"
          label="Short Description"
          :helpText="'The main service overview. This description customer sees first.'"
          required
          textarea
        ></HelpFormInputVue>
      </v-col>
      <v-col class="overview-editor-col" cols="6" lg="4" md="4" sm="6">
        <ServiceFitRateVue
          v-model="form"
          dark
          editable
          class="mx-5"
        ></ServiceFitRateVue>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";
import ServiceFitRateVue from "../../../atoms/service/ServiceFitRate.vue";


export default {
  props: {
    value: {},
  },
  data() {
    return {
      valid: true,
      form:this.value,
      // form: {
      //   overview:this.value.overview,
      //   idea:this.value.idea,
      //   startup:this.value.startup,
      //   product:this.value.product,
      //   enterprise:this.value.enterprise
      // },

      // ======rules
      serviceOverviewRules: [(v) => !!v || "Overview is required"],
    };
  },
  components: {
    ServiceFitRateVue,
    HelpFormInputVue
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    }
  },
};
</script>


<style lang="scss" scoped>
.overview-editor-col{
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>